import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Card, Container, Divider, FormControl, FormControlLabel, List, ListItem, ListItemText, Switch, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Menu, MenuItem, Dialog, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppBar from '@material-ui/core/AppBar';
import { BackgroundType, useSettings } from '../../contexts/SettingsProvider';
import { CustomThemeParams, themeMap } from '../../themes/theme'
import ThemeItem from './ThemeItem';
import CustomThemeBuilder from './CustomThemeBuilder';
import CustomCardBuilder from './CustomCardBuilder';
import { isStringNumber } from '../../util/util';
import BackgroundItem from './BackgroundItem';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));

interface ISettingsProps {
    handleCloseSettings: () => void
}

export default function Settings({ handleCloseSettings }: ISettingsProps) {
    const classes = useStyles()
    const { theme, setTheme, getCustomTheme, customThemeParams, setCustomThemeParams, name, setName,
        autoUseName, setAutoUseName, shouldSavePreviousRoomId, setShouldSavePreviousRoomId,
        customCardColor, setCustomCardColor, useCustomCardColor, setUseCustomCardColor,
        cardImage, setCardImage, customCardImage, setCustomCardImage,
        backgroundType, setBackgroundType } = useSettings()

    const [editCustomThemeParams, setEditCustomThemeParams] = useState(customThemeParams)

    function handleThemeSelected(themeKey: string) {
        setTheme(themeKey)
    }

    function handleNameTextChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setName(e.target.value)
    }

    function handleAutoUseNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setAutoUseName(e.target.checked)
    }

    function handleSavePreviousRoomIdChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setShouldSavePreviousRoomId(e.target.checked)
    }

    function handleCustomThemeEdited(newParams: CustomThemeParams) {
        setEditCustomThemeParams(newParams)
    }

    function handleCustomThemeUpdatePressed() {
        setCustomThemeParams(editCustomThemeParams)
    }

    function renderThemesList() {
        var useThemes = Array.from(themeMap.entries())
        useThemes.push(['custom', { theme: getCustomTheme(editCustomThemeParams), name: 'Custom' }])
        return useThemes.map(list =>
            <ThemeItem key={`theme-item-${list[0]}`} themeKey={list[0]} theme={list[1]} isSelected={theme == list[0]} onSelected={handleThemeSelected} />
        )
    }

    function renderBackgroundList() {
        return Object.keys(BackgroundType).filter(value => !isStringNumber(value)).map(type =>
            <BackgroundItem key={`background-item-${type}`} type={BackgroundType[type as keyof typeof BackgroundType]} isSelected={BackgroundType[type as keyof typeof BackgroundType] === backgroundType} onSelected={(newType: BackgroundType) => {
                setBackgroundType(newType)
            }} />
        )
    }

    return (
        <>
            <AppBar>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseSettings} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Settings
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ marginTop: "80px" }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">

                        <Typography variant="h6">General</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl component="fieldset">
                            <TextField label="Name"
                                onChange={handleNameTextChanged}
                                defaultValue={name}
                                inputProps={{ maxLength: 30 }}
                                style={{ marginBottom: '16px' }} />
                            <FormControlLabel
                                control={<Switch
                                    checked={autoUseName}
                                    onChange={handleAutoUseNameChanged}
                                    color="primary" />}
                                label="Automatically use name when joining rooms" />

                            <FormControlLabel
                                control={<Switch
                                    checked={shouldSavePreviousRoomId}
                                    onChange={handleSavePreviousRoomIdChanged}
                                    color="primary" />}
                                label="Save previous room Id" />
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">

                        <Typography variant="h6">Theme</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container>
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                                {renderThemesList()}
                            </div>
                            {theme === 'custom' ? <CustomThemeBuilder
                                updateDisabled={customThemeParams === editCustomThemeParams}
                                editCustomThemeParams={editCustomThemeParams}
                                onUpdate={handleCustomThemeEdited}
                                onUpdateClicked={handleCustomThemeUpdatePressed} /> : <></>}
                        </Container>


                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">

                        <Typography variant="h6">Background</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container>
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                                {renderBackgroundList()}
                            </div>
                        </Container>


                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">

                        <Typography variant="h6">Cards</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomCardBuilder
                            customCardColor={customCardColor}
                            setCustomCardColor={setCustomCardColor}
                            useCustomCardColor={useCustomCardColor}
                            setUseCustomCardColor={setUseCustomCardColor}
                            cardImage={cardImage}
                            setCardImage={setCardImage}
                            customCardImage={customCardImage}
                            setCustomCardImage={setCustomCardImage} />
                    </AccordionDetails>
                </Accordion>
            </Container>
        </>
    )
}
