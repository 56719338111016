import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import RoomProvider from '../../contexts/RoomProvider'
import SocketProvider from '../../contexts/SocketProvider'
import RoomBase from './RoomBase'
import { appName } from '../../util/util'

export interface IParamTypes {
    id: string
}

export default function Room() {
    let { id } = useParams<IParamTypes>()

    useEffect(() => {
        document.title = appName
    }, [])

    return (
        <SocketProvider roomId={id} playerId={uuidV4()}>
            <RoomProvider>
                <RoomBase />
            </RoomProvider>
        </SocketProvider>
    )
}
