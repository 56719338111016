import React from "react"
import { Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import { BackgroundType, useSettings } from "../contexts/SettingsProvider";
import Particles from 'react-particles-js'
import { getBackgroundParticles } from "../themes/backgroundParticles";

export default function CanvasBackground({ }) {
    const theme = useTheme<Theme>()
    const { backgroundType } = useSettings()

    return (
        <div className="backgroundParticles">
            {
                backgroundType !== BackgroundType.None ?
                    <Particles params={getBackgroundParticles(backgroundType, theme)} />
                    : <></>
            }
        </div>
    )
}