import React from 'react';
import { CssBaseline } from '@material-ui/core'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Header from './Header';
import NotFound from './NotFound';
import Home from './Home';
import Room from './room/Room';
import SettingsProvider from '../contexts/SettingsProvider';
import CanvasBackground from './CanvasBackground';
import Changelog from './Changelog';

function App() {
  return (
    <div style={{ height: '100vh' }}>
      <SettingsProvider>
        <CssBaseline />
        <Router>
          <CanvasBackground />

          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/room/:id" component={Room} />
            <Route path="/changelog" component={Changelog} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Router>
      </SettingsProvider>
    </div>
  );
}

export default App;
