import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton,
         InputLabel, List, ListItem, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useState } from 'react'
import { GameType, getGameTypeName, RoomProperties } from '../../models/ServerModels'
import { isBlank, isStringNumber } from '../../util/util'

class VotingScheme {
    constructor(public id: string, public name: string, public values: string[]) { }
}

const scrumFibonacciScheme = new VotingScheme('scrumFibonacci', 'Scrum Fibonacci', ['0', '½', '1', '2', '3', '5', '8', '13', '20', '40', '100', '?'])
const fibonacciScheme = new VotingScheme('fibonacci', 'Fibonacci', ['0', '1', '2', '3', '5', '8', '13', '21', '34', '55', '89', '?'])
const tShirtScheme = new VotingScheme('tShirt', 'T-shirt Sizes', ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '?'])
const customScheme = new VotingScheme('custom', 'Custom', [])

const votingSchemes: VotingScheme[] = [scrumFibonacciScheme, fibonacciScheme, tShirtScheme, customScheme]

const maxCustomItems = 25

interface ICreateDialogContentProps {
    onCancel: () => void
    onCreate: (properties: RoomProperties) => void
    existingProperties: RoomProperties | null
}

export default function CreateDialogContent({ onCancel, onCreate, existingProperties }: ICreateDialogContentProps) {

    const originalVotingScheme = votingSchemes.find(scheme =>
        existingProperties?.votingScheme.length === scheme.values.length &&
        existingProperties?.votingScheme.every((element, index) => element == scheme.values[index])) ?? existingProperties === null ? scrumFibonacciScheme : customScheme

    const [nameText, setNameText] = useState(existingProperties?.name ?? "")
    const [votingScheme, setVotingScheme] = useState(originalVotingScheme)
    const [gameType, setGameType] = useState(existingProperties?.gameType ?? GameType.PickCards)
    const [anonymous, setAnonymous] = useState(existingProperties?.anonymous ?? false)
    const [disableEmotes, setDisableEmotes] = useState(existingProperties?.disableEmotes ?? false)
    const [customSchemeItems, setCustomSchemeItems] = useState<string[]>(originalVotingScheme.id == customScheme.id ? (existingProperties?.votingScheme ?? [""]) : [""])

    function handleCreateClicked() {
        let values = votingScheme.id == customScheme.id ? customSchemeItems.filter(item => item !== "") : votingScheme.values
        let properties = new RoomProperties(nameText, values, gameType, anonymous, disableEmotes)
        onCreate(properties)
    }

    function handleNameTextChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setNameText(e.currentTarget.value)
    }

    function handleVotingSchemeChanged(e: React.ChangeEvent<{ value: unknown }>) {
        const targetKey = e.target.value as string
        const newScheme = votingSchemes.find(scheme => scheme.id === targetKey)
        if (newScheme != null) {
            setVotingScheme(newScheme)
        }
    }

    function handleGameTypeChanged(e: React.ChangeEvent<{ value: unknown }>) {
        setGameType(GameType[e.target.value as keyof typeof GameType])
    }

    return (
        <>
            <DialogTitle id="form-dialog-title">
                {existingProperties == null ? "Create Room" : "Edit Room"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {existingProperties == null ? "Create a new room. Anyone that uses the room's url will be able to join.  These settings can be changed at any time." : ""}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Room Name"
                    type="email"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    defaultValue={nameText}
                    onChange={handleNameTextChanged}
                />

                <FormControl style={{ width: '100%', marginTop: '16px' }}>
                    <InputLabel id="select-game-type-label">Game Type</InputLabel>
                    <Select
                        labelId="select-game-type-label"
                        id="select-game-type"
                        value={gameType}
                        onChange={handleGameTypeChanged}>
                        {Object.keys(GameType).filter(value => !isStringNumber(value)).map(type =>
                            <MenuItem key={'menuItem-' + type} value={type}>{getGameTypeName(GameType[type as keyof typeof GameType])}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                {gameType == GameType.PickCards ?
                    <>
                        <FormControl style={{ width: '100%', marginTop: '16px' }}>
                            <InputLabel id="select-voting-scheme-label">Voting Scheme</InputLabel>
                            <Select
                                labelId="select-voting-scheme-label"
                                id="select-voting-scheme"
                                value={votingScheme.id}
                                onChange={handleVotingSchemeChanged}>
                                {votingSchemes.map(scheme =>
                                    <MenuItem key={'menuItem-' + scheme.id} value={scheme.id}>{`${scheme.name}` + (scheme.values.length > 0 ? ` (${scheme.values.join(', ')})` : "")}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {votingScheme.id === customScheme.id ?
                            <>
                                <Typography style={{ marginTop: "24px" }}>
                                    Custom Scheme
                                </Typography>
                                <List>
                                    {customSchemeItems.map((item: string, index: number, original: string[]) =>
                                        <ListItem>
                                            <IconButton
                                                style={{ marginRight: "16px" }}
                                                id={"custom-scheme-item-button-" + index}
                                                onClick={() => {
                                                    let items = Array.from(original)
                                                    items.splice(index, 1)
                                                    setCustomSchemeItems(items)
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id={"custom-scheme-item-field-" + index}
                                                label="Card Text"
                                                type="email"
                                                inputProps={{ maxLength: 7 }}
                                                defaultValue={item}
                                                onChange={(e) => {
                                                    let items = [...customSchemeItems]
                                                    items[index] = e.currentTarget.value
                                                    setCustomSchemeItems(items)
                                                }}
                                            />
                                        </ListItem>
                                    )}
                                    <Button
                                        disabled={customSchemeItems.length >= maxCustomItems}
                                        onClick={() => {
                                            if (customSchemeItems.length >= maxCustomItems) { return }
                                            setCustomSchemeItems([...customSchemeItems, ""])
                                        }}
                                    >
                                        Add Card
                                    </Button>
                                </List>
                            </>
                            : <></>
                        }

                    </>
                    : <></>
                }

                <FormControl style={{ width: '100%', marginTop: '16px' }}>
                    <FormControlLabel
                        id="keep-anonymous-check"
                        control={
                            <Switch checked={anonymous} onChange={(e) => setAnonymous(e.target.checked)} name="anonymous" />
                        }
                        label="Keep players anonymous"
                    />
                </FormControl>

                <FormControl style={{ width: '100%', marginTop: '16px' }}>
                    <FormControlLabel
                        id="disable-emotes-check"
                        control={
                            <Switch checked={disableEmotes} onChange={(e) => setDisableEmotes(e.target.checked)} name="anonymous" />
                        }
                        label="Disable emote reactions"
                    />
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreateClicked}
                    disabled={isBlank(nameText)}
                    color="primary">
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}
