import { Theme } from '@material-ui/core'
import { BackgroundType } from "../contexts/SettingsProvider";
import { IParticlesParams } from 'react-particles-js'

export function getBackgroundParticles(type: BackgroundType, theme: Theme): IParticlesParams {
    const leaf1Image = require('../assets/backgrounds/leaf.png');
    const leaf2Image = require('../assets/backgrounds/leaf2.png');
    const leaf3Image = require('../assets/backgrounds/leaf3.png');

    switch (type) {
        case BackgroundType.None: return {}
        case BackgroundType.Snow: return {
            particles: {
                color: {
                    value: "#ffffff"
                },
                shape: {
                    type: "circle",
                },
                opacity: {
                    value: 0.7,
                    anim: {
                        speed: 1
                    }
                },
                size: {
                    value: 6,
                    random: true
                },
                move: {
                    speed: 1,
                    direction: "bottom",
                    outMode: "out",
                    bounce: false
                },
                number: {
                    value: 100,
                },
                lineLinked: {
                    enable: false
                },
            },
        }
        case BackgroundType.Connected: return {
            particles: {
                color: {
                    value: theme.palette.primary.main
                },
                lineLinked: {
                    color: theme.palette.primary.main
                },
                move: {
                    speed: 1
                },
                number: {
                    value: 35,
                    density: {
                        enable: true,
                        value_area: 1000,
                    }
                },
            }
        }
        case BackgroundType.Stars: return {
            particles: {
                color: {
                    value: theme.palette.primary.main
                },
                shape: {
                    type: "star",
                    stroke: {
                        width: 1,
                        color: theme.palette.primary.main
                    }
                },
                opacity: {
                    value: 0.3,
                    anim: {
                        speed: 0.5
                    }
                },
                size: {
                    value: 20,
                    random: true
                },
                move: {
                    speed: 0.5
                },
                number: {
                    value: 25,
                    density: {
                        enable: false,
                        value_area: 1000,
                    }
                },
                lineLinked: {
                    enable: false
                },
            },
        }
        case BackgroundType.FallLeaves: return {
            particles: {
                shape: {
                    type: "images",
                    images: [
                        {
                            src: leaf1Image
                        },
                        {
                            src: leaf2Image
                        },
                        {
                            src: leaf3Image
                        }
                    ]
                },
                opacity: {
                    value: 0.9,
                    anim: {
                        speed: 1
                    }
                },
                size: {
                    value: 25,
                    random: true,
                },
                move: {
                    speed: 0.5,
                    direction: "bottom-right",
                    outMode: "out",
                    bounce: false
                },
                number: {
                    value: 25,
                },
                lineLinked: {
                    enable: false
                },
            },
        }
        case BackgroundType.Bubbles: return {
            particles: {
                color: {
                    value: theme.palette.primary.main
                },
                shape: {
                    type: "circle",
                    stroke: {
                        width: 1,
                        color: theme.palette.primary.main
                    }
                },
                opacity: {
                    value: 0.2,
                    anim: {
                        speed: 0.5
                    }
                },
                size: {
                    value: 100,
                    random: true
                },
                move: {
                    speed: 0.25
                },
                number: {
                    value: 25,
                },
                lineLinked: {
                    enable: false
                },
            },
        }
    }
}