import React, { useEffect } from 'react'
import { Theme, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import { anime } from 'react-anime';

interface IPickCardProps {
    value: string,
    isSelected: boolean,
    onSelected: (value: string) => void
}

export default function PickCard({ value, isSelected, onSelected }: IPickCardProps) {
    const theme = useTheme<Theme>()

    function handleCardClicked() {
        onSelected(value)
    }

    useEffect(() => {
        anime({
            targets: ".card-pick-selected",
            translateY: -25,
            scale: 1.2,
            duration: 400
        })
        anime({
            targets: ".card-pick",
            translateY: 0,
            scale: 1,
            duration: 400
        })
    }, [isSelected])

    return (
        <div className={isSelected ? "card-pick-selected" : "card-pick"} onClick={handleCardClicked}>
            <Typography variant="body1" style={{ color: theme.palette.primary.dark }}>
                <b>{value}</b>
            </Typography>

        </div>
    )
}
