import { Card, CardContent, Theme, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import React, { useEffect } from 'react'
import { anime } from 'react-anime';
import { BackgroundType, getBackgroundTypeName } from '../../contexts/SettingsProvider';

interface IBackgroundItemProps {
    type: BackgroundType
    isSelected: boolean,
    onSelected: (type: BackgroundType) => void
}

export default function BackgroundItem({ type, isSelected, onSelected }: IBackgroundItemProps) {
    const theme = useTheme<Theme>()

    useEffect(() => {
        anime({
            targets: ".backgroundItemSelected",
            scale: 1.2,
            duration: 400
        })
        anime({
            targets: ".backgroundItem",
            scale: 1,
            duration: 400
        })
    }, [isSelected])

    function handleClicked() {
        onSelected(type)
    }

    function getBgColor(): string {
        switch (type) {
            case BackgroundType.Snow: return "white"
            case BackgroundType.Connected: return theme.palette.primary.main
            case BackgroundType.Stars: return theme.palette.primary.main
            case BackgroundType.FallLeaves: return "#E09119"
            case BackgroundType.Bubbles: return theme.palette.primary.main
            default: return "black"
        }
    }

    function getTextColor(): string {
        switch (type) {
            case BackgroundType.Snow: return "#483D8B"
            case BackgroundType.Connected: return theme.palette.text.primary
            case BackgroundType.Stars: return theme.palette.text.primary
            case BackgroundType.FallLeaves: return "#000000"
            case BackgroundType.Bubbles: return theme.palette.text.primary
            default: return "white"
        }
        return ""
    }

    return (
        <Card
            className={isSelected ? 'backgroundItemSelected' : 'backgroundItem'}
            style={{ width: '150px', margin: '16px', backgroundColor: getBgColor(), color: getTextColor() }}
            onClick={handleClicked}>

            <CardContent>
                <Typography variant="body1">
                    {getBackgroundTypeName(type)}
                </Typography>
            </CardContent>
        </Card>
    )
}