import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { EventLog } from './RoomPlaying'

interface IEventHistoryDialogProps {
    events: EventLog[]
}

export default function EventHistoryDialogContent({ events }: IEventHistoryDialogProps) {
    return (
        <TableContainer component={Paper} style={{ maxHeight: "400px" }}>
            <Table stickyHeader aria-label="History Table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "30%" }}> Time </TableCell>
                        <TableCell align="left"> Results </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events.map(log =>
                        <TableRow key={log.dateString} >
                            <TableCell> {log.dateString} </TableCell>
                            <TableCell> {log.eventString} </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
