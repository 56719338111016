import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import { appName } from '../util/util'

export default function NotFound() {
    const dogArt = require('../assets/dogs-playing-poker.png');

    useEffect(() => {
        document.title = `Page not found - ${appName}`
    }, [])

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{
                textAlign: 'center'
            }}>

                <Typography variant="h2"> 404 </Typography>
                <Typography variant="h5"> Nothing to see here. </Typography>

                <br />
                <img src={String(dogArt)} style={{ maxHeight: "450px", minHeight: "25%" }} />
            </div>

        </div>
    )
}
