import { useEffect, useRef } from 'react'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function isBlank(str: string) {
  return !str.replace(/\s/g, '').length
}

export function isStringNumber(value: string) {
  return isNaN(Number(value)) === false
}

export function median(values: number[]): number {
  if (values.length === 0) throw new Error("No inputs");

  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export function allValuesAreTheSame(values: string[]): boolean {
  return values.every((val, i, arr) => val === arr[0])
}

export function shallowStringCompare(a: string[], b: string[]): boolean {
  return JSON.stringify(a) == JSON.stringify(b)
}

export function randomItem<T>(values: T[]) {
  return values[Math.floor(Math.random() * values.length)]
}

export function limitDecimalPlaces(num: number, places: number): number {
  let multiplier = Math.pow(10, places)
  return Math.round(num * multiplier) / multiplier
}

export const appName = 'Effort Estimation'