import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom'
import { Dialog, Grow, GrowProps, Theme, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Settings from './settings/Settings';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { anime } from 'react-anime';

const logo = require('../assets/logo.png');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props: TransitionProps, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function Header() {
  const theme = useTheme<Theme>()
  const classes = useStyles();
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  function handleSettingsClicked() {
    setSettingsOpen(true)
  }

  function handleCloseSettings() {
    setSettingsOpen(false)
  }

  function animateCardHover() {
    let timeline = anime.timeline({
      targets: ".animCardLogoFront",
      easing: 'easeOutElastic'
    })
    timeline.add({
      targets: ".animHeaderCardLogoFront, .animHeaderCardLogoBack",
      duration: 800,
      rotate: 0,
      translateX: 0,
    })
    timeline.add({
      targets: ".animHeaderCardLogoFront",
      duration: 800,
      rotate: 25,
      translateX: 15,
    }, "-=500")
    timeline.add({
      targets: ".animHeaderCardLogoBack",
      duration: 800,
      rotate: -25,
      translateX: -15,
    }, "-=750")
  }

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <Link to={"/"} onMouseEnter={animateCardHover}>
          <div style={{ position: "relative", width: "45px", height: "45px", margin: "16px" }}>
            <div className="animHeaderCardLogoFront logoCardMini" style={{ backgroundColor: theme.palette.primary.main, zIndex: 3, transform: "rotate(25deg) translateX(15px)" }} />
            <div className="animHeaderCardLogoMiddle logoCardMini" style={{ backgroundColor: theme.palette.primary.main, filter: "brightness(70%)", zIndex: 2 }} />
            <div className="animHeaderCardLogoBack logoCardMini" style={{ backgroundColor: theme.palette.primary.main, filter: "brightness(50%)", zIndex: 1, transform: "rotate(-25deg) translateX(-15px)" }} />
          </div>
        </Link>

        <div style={{ marginTop: "16px", color: theme.palette.primary.main, fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica" }}>
          <div>EFFORT</div>
          <div>ESTIMATION</div>
        </div>

        <div style={{ flex: 1 }} />
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleSettingsClicked} style={{ margin: "16px" }}>
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Dialog fullScreen open={settingsOpen} onClose={handleCloseSettings} TransitionComponent={Transition}>
        <Settings handleCloseSettings={handleCloseSettings} />
      </Dialog>
    </AppBar>
  )
}
