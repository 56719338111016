import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import EnterName from './EnterName'
import RoomPlaying from './RoomPlaying'
import { ConnectionState, useRoom } from '../../contexts/RoomProvider'
import { useSettings } from '../../contexts/SettingsProvider'
import { useSocket } from '../../contexts/SocketProvider'
import { useParams } from 'react-router-dom'
import { Container, Typography } from '@material-ui/core';
import { IParamTypes } from './Room';
import { JoinRoomRequest } from '../../models/ServerModels';

export default function RoomBase() {
    const { connectionState, joinRoom, roomProperties } = useRoom()
    const socket = useSocket()
    const history = useHistory()
    const { name, autoUseName, setPreviousRoomId } = useSettings()
    const [hasEnteredName] = useState(false)
    let { id } = useParams<IParamTypes>()

    function onFinishEnteringName(name: string, isSpectator: boolean) {
        joinRoom(new JoinRoomRequest(name, isSpectator))
    }

    useEffect(() => {
        if (socket != null && autoUseName) {
            joinRoom(new JoinRoomRequest(name, false))
        }
    }, [socket])

    useEffect(() => {
        if (connectionState === ConnectionState.Connected) {
            setPreviousRoomId(id)
            document.title = roomProperties.name
        } else if (connectionState === ConnectionState.InvalidRoom) {
            history.push(`/404`)
        }
    }, [connectionState])

    function renderState() {
        switch (connectionState) {
            case ConnectionState.Disconnected:
                return <div> </div>
            case ConnectionState.WaitingToJoin:
                return !hasEnteredName ? <EnterName onFinishEnteringName={onFinishEnteringName} /> : <div> Joining... </div>
            case ConnectionState.Connected:
                return <RoomPlaying />
            case ConnectionState.ConnectFailed:
                return <Container style={{ height: "80vh" }}>
                    <Typography variant="h5">Disconnected from room</Typography>
                </Container>
            case ConnectionState.InvalidRoom:
                return <Container style={{ height: "80vh" }}>
                    <Typography variant="h5">Invalid room Id</Typography>
                </Container>
            default:
                return <div> Invalid state! </div>
        }
    }

    return (
        <>
            {renderState()}
        </>
    )
}
