/*
    Contains all of the models shared between the server and client.

    Ideally this file would be shared, for now just copy it to both the client and server projects.
*/

export class Estimate {
    constructor(
        public playerId: string,
        public values: string[]) { }
}

export class Player {
    constructor(
        public id: string,
        public name: string,
        public isSpectator: boolean,
        public estimate: Estimate,
        public hasEstimated: boolean) { }
}

export class Room {
    constructor(public clientData: RoomClientData, public playerEstimates: Estimate[]) { }
}

export class RoomClientData {
    constructor(
        public properties: RoomProperties,
        public state: RoomState) { }
}

export class RoomProperties {
    constructor(
        public name: string,
        public votingScheme: string[],
        public gameType = GameType.PickCards,
        public anonymous = false,
        public disableEmotes = false) { }
}

export class RoomState {
    constructor(
        public players: Player[],
        public status: RoomStatus,
        public timer: TimerData | null,
        public lockOwnerId: string | null) { }
}

export class JoinRoomRequest {
    constructor(
        public name: string,
        public isSpectator: boolean) { }
}

export class EditRoomRequest {
    constructor(public properties: RoomProperties) { }
}

export class SetTimerRequest {
    constructor(public seconds: number) { }
}

export class PlayerEmoteResponse {
    constructor(public playerId: string, public value: string) { }
}

export class JoinFailedResponse {
    constructor(public reason: FailureReason) { }
}

export class FailureReason {
    constructor(
        public key: ErrorMessage,
        public description: string) { }
}

export class TimerData {
    constructor(
        public isActive: boolean,
        public serverStartTimeMillis: number,
        public totalTimeMillis: number
    ) { }
}

export enum SocketMessage {
    Connect = "connect",
    ConnectFailed = "connect-failed",
    RoomJoined = "room-joined",
    StateUpdated = "state-updated",
    PropertiesUpdated = "properties-updated",
    MakeEstimate = "make-estimate",
    PerformEmote = "perform-emote",
    EmotePerformed = "emote-performed",
    JoinRoom = "join-room",
    ShowCards = "show-cards",
    NewGame = "new-game",
    EditRoom = "edit-room",
    SetTimer = "set-timer",
    LockRoom = "lock-room",
    Spectate = "spectate",
    Disconnect = "disconnect",
    JoinFailed = "join-failed"
}

export enum StatusMessage {
    Success = "success",
    Failure = "failure"
}

export enum ErrorMessage {
    InvalidId = "invalid-id",
    NotFound = "not-found",
    Error = "error"
}

export enum RoomStatus {
    Estimating = "estimating",
    Results = "results"
}

export enum GameType {
    PickCards = "PickCards",
    FreeResponse = "FreeResponse"
}

export function getGameTypeName(type: GameType): string {
    switch (type) {
        case GameType.PickCards: return "Pick Cards"
        case GameType.FreeResponse: return "Free Response"
    }
}