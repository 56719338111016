import React, { useState, useContext, useEffect, useCallback } from 'react'
import { Socket } from 'socket.io-client'
import { EditRoomRequest, Estimate, JoinRoomRequest, Player, PlayerEmoteResponse, RoomProperties, RoomState, RoomStatus, SetTimerRequest, SocketMessage, TimerData } from '../models/ServerModels'
import { usePrevious } from '../util/util'
import { useSocket } from './SocketProvider'

interface IRoomContext {
    roomState: RoomState
    setRoomState: (state: RoomState) => void
    previousRoomState: RoomState | undefined
    roomProperties: RoomProperties
    setRoomProperties: (properties: RoomProperties) => void
    previousRoomProperties: RoomProperties | undefined
    player: Player | undefined
    connectionState: ConnectionState
    setConnectionState: (state: ConnectionState) => void
    joinRoom: (request: JoinRoomRequest) => void
    makeEstimate: (estimate: Estimate) => void
    showCards: () => void
    newGame: () => void
    editRoom: (properties: RoomProperties) => void
    setTimer: (seconds: number) => void
    lockRoom: () => void
    toggleSpectator: () => void
    performEmote: (emote: string) => void
    clearEmote: (playerId: string) => void
    playerEmotes: Map<string, string>
}

const RoomContext = React.createContext({} as IRoomContext)

export function useRoom() {
    return useContext(RoomContext)
}

export enum ConnectionState {
    Disconnected = "disconnected",
    WaitingToJoin = "waitingToJoin",
    Connected = "connected",
    InvalidRoom = "invalidRoom",
    ConnectFailed = "connectFailed"
}

export const roomStatuses = {
    estimating: 'estimating',
    results: 'results'
}

interface IRoomProviderProps {
    children: JSX.Element | JSX.Element[]
}

export default function RoomProvider({ children }: IRoomProviderProps) {
    const [roomState, setRoomState] = useState<RoomState>(new RoomState([], RoomStatus.Estimating, null, null))
    const previousRoomState = usePrevious<RoomState | undefined>(roomState)
    const [roomProperties, setRoomProperties] = useState<RoomProperties>(new RoomProperties("", []))
    const previousRoomProperties = usePrevious<RoomProperties | undefined>(roomProperties)
    const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.WaitingToJoin)
    const [player, setPlayer] = useState<Player>()
    const [playerEmotes, setPlayerEmotes] = useState(new Map<string, string>())
    const socket = useSocket()

    function joinRoom(request: JoinRoomRequest) {
        socket.socket?.emit(SocketMessage.JoinRoom, request)
    }

    function makeEstimate(value: Estimate) {
        socket.socket?.emit(SocketMessage.MakeEstimate, value)
    }

    function showCards() {
        socket.socket?.emit(SocketMessage.ShowCards, {})
    }

    function newGame() {
        socket.socket?.emit(SocketMessage.NewGame, {})
    }

    function editRoom(properties: RoomProperties) {
        socket.socket?.emit(SocketMessage.EditRoom, new EditRoomRequest(properties))
    }

    function setTimer(seconds: number) {
        socket.socket?.emit(SocketMessage.SetTimer, new SetTimerRequest(seconds))
    }

    function lockRoom() {
        socket.socket?.emit(SocketMessage.LockRoom, {})
    }

    function performEmote(value: string) {
        if (!roomProperties.disableEmotes && player != undefined && (playerEmotes.get(player!.id) ?? "") === "") {
            socket.socket!.emit(SocketMessage.PerformEmote, value)
        }
    }

    function getConnectionState(): ConnectionState {
        return connectionState
    }

    function toggleSpectator() {
        socket.socket!.emit(SocketMessage.Spectate, {})
    }

    function clearEmote(playerId: string) {
        updatePlayerEmotes(playerId, "")
    }

    function updatePlayerEmotes(playerId: string, value: string) {
        setPlayerEmotes(prev => new Map([...prev, [playerId, value]]))
    }

    const handleConnect = useCallback(() => {
        setConnectionState(ConnectionState.WaitingToJoin)
    }, [])

    const handleRoomJoined = useCallback(({ roomId, room }) => {
        setRoomState(room.state)
        setRoomProperties(room.properties)
        // TODO - Try to prevent emotes from being received while in ConnectionState.WaitingToJoin
        setPlayerEmotes(new Map()) // Reset emotes before joining.  
        setConnectionState(ConnectionState.Connected)
    }, [])

    const handleJoinFailed = useCallback(() => {
        setConnectionState(ConnectionState.InvalidRoom)
    }, [])

    const handleConnectFailed = useCallback(() => {
        setConnectionState(ConnectionState.ConnectFailed)
    }, [])

    const handleStateUpdated = useCallback(({ state }) => {
        setRoomState(state)
    }, [])

    const handlePropertiesUpated = useCallback(properties => {
        setRoomProperties(properties)
    }, [])

    const handleEmotePerformed = useCallback((emote: PlayerEmoteResponse) => {
        if ((playerEmotes.get(emote.playerId) ?? "" !== "")) {
            return
        }
        updatePlayerEmotes(emote.playerId, emote.value)
    }, [connectionState])

    class ServerResponse {
        constructor(public message: SocketMessage, public action: any) { }
    }

    const serverResponses = {
        connect: new ServerResponse(SocketMessage.Connect, handleConnect),
        connectFailed: new ServerResponse(SocketMessage.ConnectFailed, handleConnectFailed),
        disconnect: new ServerResponse(SocketMessage.Disconnect, handleConnectFailed),
        roomJoined: new ServerResponse(SocketMessage.RoomJoined, handleRoomJoined),
        joinFailed: new ServerResponse(SocketMessage.JoinFailed, handleJoinFailed),
        roomStateUpdated: new ServerResponse(SocketMessage.StateUpdated, handleStateUpdated),
        roomPropertiesUpdated: new ServerResponse(SocketMessage.PropertiesUpdated, handlePropertiesUpated),
        emotePerformed: new ServerResponse(SocketMessage.EmotePerformed, handleEmotePerformed)
    }

    function handleResponse(response: ServerResponse): (() => Socket<any, any>) | null {
        if (socket.socket == null) return null
        socket.socket!.on(response.message, response.action)
        return () => socket.socket!.off(response.message)
    }

    useEffect(() => {
        handleResponse(serverResponses.connect)
    }, [socket, serverResponses.connect])
    useEffect(() => {
        handleResponse(serverResponses.connectFailed)
    }, [socket, serverResponses.connectFailed])
    useEffect(() => {
        handleResponse(serverResponses.disconnect)
    }, [socket, serverResponses.disconnect])
    useEffect(() => {
        handleResponse(serverResponses.roomJoined)
    }, [socket, serverResponses.roomJoined])
    useEffect(() => {
        handleResponse(serverResponses.joinFailed)
    }, [socket, serverResponses.joinFailed])
    useEffect(() => {
        handleResponse(serverResponses.roomStateUpdated)
    }, [socket, serverResponses.roomStateUpdated])
    useEffect(() => {
        handleResponse(serverResponses.roomPropertiesUpdated)
    }, [socket, serverResponses.roomPropertiesUpdated])
    useEffect(() => {
        handleResponse(serverResponses.roomPropertiesUpdated)
    }, [socket, serverResponses])
    useEffect(() => {
        handleResponse(serverResponses.emotePerformed)
    }, [socket])

    useEffect(() => {
        let findPlayer = roomState.players.find(p => p.id === socket.playerId)
        if (findPlayer != undefined) {
            setPlayer(findPlayer)
        }
    }, [roomState])

    const value: IRoomContext = {
        roomState: roomState,
        setRoomState: setRoomState,
        previousRoomState: previousRoomState,
        roomProperties: roomProperties,
        setRoomProperties: setRoomProperties,
        previousRoomProperties: previousRoomProperties,
        player: player,
        connectionState: connectionState,
        setConnectionState: setConnectionState,
        joinRoom: joinRoom,
        makeEstimate: makeEstimate,
        showCards: showCards,
        newGame: newGame,
        editRoom: editRoom,
        setTimer: setTimer,
        lockRoom: lockRoom,
        toggleSpectator: toggleSpectator,
        performEmote: performEmote,
        clearEmote: clearEmote,
        playerEmotes: playerEmotes
    }

    return (
        <RoomContext.Provider value={value}>
            {children}
        </RoomContext.Provider>
    )
}
