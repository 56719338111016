import React, { useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { chartColors } from '../../themes/theme'
import { useTheme } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import { Player } from '../../models/ServerModels';
import { allValuesAreTheSame, isStringNumber, limitDecimalPlaces, median, randomItem } from '../../util/util';

const Chart = require('react-chartjs-2').Chart

interface IResultsChartProps {
    players: Player[]
}

function convertData(list: string[]): Map<string, number> {
    return new Map(list.map(
        x => [x, list.filter(y => y === x).length]
    ))
}

export default function ResultsChart({ players }: IResultsChartProps) {
    const theme = useTheme<Theme>()
    Chart.defaults.global.defaultFontColor = theme.palette.text.primary

    const allEstimates = players.map(p => p.estimate.values).flat().filter(e => e.length > 0).map(p => p === "½" ? "0.5" : p)
    const estimateNumbers = allEstimates.filter(e => isStringNumber(e)).map(e => parseFloat(e))
    const isUnanimous = allEstimates.length > 0 && allValuesAreTheSame(allEstimates)
    const meanEstimate = estimateNumbers.length > 0 ? limitDecimalPlaces((estimateNumbers.reduce((a, b) => a + b) / estimateNumbers.length), 2) : 0
    const medianEstimate = estimateNumbers.length > 0 ? limitDecimalPlaces(median(estimateNumbers), 2) : 0

    const unanimousWords = ["Unanimous", "Undisputed", "Like-Minded", "Agreement"]
    const unanimousWord = useState(randomItem(unanimousWords))
    const unanimousEmojis = ["👍", "💯", "👏", "😎", "😏", "🔥", "👀", "🎉", "🎂", "🐱‍👤", "🌟", "🧠", "🕺", "🎯"]
    const unanimousEmoji = useState(randomItem(unanimousEmojis))

    function getBarData() {
        const list = convertData(allEstimates)

        return {
            datasets: [{
                data: Array.from(list.values()),
                borderWidth: 0,
                backgroundColor: chartColors
            }],
            labels: Array.from(list.keys())
        }
    }

    return (
        <div style={{ width: "400px" }}>
            <Doughnut
                data={getBarData()}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    tooltips: { enabled: false },
                    hover: { mode: null },
                    cutoutPercentage: 70,
                    legend: {
                        display: true,
                        position: 'bottom'
                    }
                }} />

            {isUnanimous ?
                <Typography variant="h6">{unanimousWord}! {unanimousEmoji}</Typography>
                : meanEstimate > 0 ? <Typography>Mean: {meanEstimate} | Median: {medianEstimate}</Typography> : <></>
            }

        </div>
    )
}
