import React from 'react'
import { Button, Container, FormControlLabel, Switch } from '@material-ui/core'
import { Typography } from '@material-ui/core';
import { Color, ColorPicker } from 'material-ui-color';
import { CustomThemeParams } from '../../themes/theme';

interface ICustomThemeBuilderProps {
    updateDisabled: boolean,
    editCustomThemeParams: CustomThemeParams,
    onUpdate: (params: CustomThemeParams) => void,
    onUpdateClicked: () => void
}

export default function CustomThemeBuilder({ updateDisabled, editCustomThemeParams, onUpdate, onUpdateClicked }: ICustomThemeBuilderProps) {

    function handleUpdateClicked() {
        onUpdateClicked()
    }

    function handleDarkModeChecked() {
        var newParams = { ...editCustomThemeParams }
        newParams.isDark = !newParams.isDark
        onUpdate(newParams)
    }

    function colorInputToColor(input: Color) {
        return `#${input.hex}`
    }

    function handlePrimaryChanged(color: Color) {
        var newParams = { ...editCustomThemeParams }
        newParams.primary = colorInputToColor(color)
        onUpdate(newParams)
    }

    function handleSecondaryChanged(color: Color) {
        var newParams = { ...editCustomThemeParams }
        newParams.secondary = colorInputToColor(color)
        onUpdate(newParams)
    }

    function handleBackgroundChanged(color: Color) {
        var newParams = { ...editCustomThemeParams }
        newParams.background = colorInputToColor(color)
        onUpdate(newParams)
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            <Container>
                <Typography variant="h6">Custom Theme Builder</Typography>
                <ColorPicker
                    value={editCustomThemeParams.background}
                    onChange={handleBackgroundChanged}
                    disableAlpha />
                <ColorPicker
                    value={editCustomThemeParams.primary}
                    onChange={handlePrimaryChanged}
                    disableAlpha />
                <ColorPicker
                    value={editCustomThemeParams.secondary}
                    onChange={handleSecondaryChanged}
                    disableAlpha />
                <FormControlLabel
                    control={<Switch
                        checked={editCustomThemeParams.isDark}
                        onChange={handleDarkModeChecked}
                        color="primary" />}
                    label="Dark Mode" />

            </Container>
            <Button
                variant="contained"
                color="primary"
                disabled={updateDisabled}
                onClick={handleUpdateClicked}
                style={{ margin: '8px' }}>
                Update
            </Button>
        </div>
    )
}
