import React, { useEffect, useState } from 'react'
import { Button, Theme, Dialog, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import { anime } from 'react-anime';
import { Link, useHistory } from 'react-router-dom';
import { appName } from '../util/util'
import axios from 'axios';
import CreateDialogContent from './join/CreateDialogContent';
import JoinDialogContent from './join/JoinDialogContent';
import { RoomProperties } from '../models/ServerModels';

export const appVersion = "2.2.0"

export default function Home() {
    const theme = useTheme<Theme>()
    const [showCreate, setShowCreate] = useState(false)
    const [showJoin, setShowJoin] = useState(false)
    const history = useHistory();

    useEffect(() => {
        document.title = appName

        let timeline = anime.timeline({
            targets: ".animCardLogoFront",
            easing: 'easeOutElastic'
        })

        timeline.add({
            duration: 500,
            delay: 100,
            scale: [0, 1],
        })
        timeline.add({
            borderRadius: [48, 8],
            top: "0%",
            height: [80, 120],
            duration: 600,
        })
        timeline.add({
            targets: ".animCardLogoMiddle, .animCardLogoBack",
            delay: 0,
            opacity: 1,
            duration: 10
        })
        timeline.add({
            duration: 800,
            rotate: 25,
            translateX: 32,
            translateY: 8
        })
        timeline.add({
            targets: ".animCardLogoBack",
            duration: 800,
            delay: 0,
            rotate: -25,
            translateX: -40,
        }, "-=750")

        timeline.add({
            targets: ".animAppTitle, .animAppSubtitle, .animButtons",
            delay: anime.stagger(50),
            translateX: [-50, 0],
            duration: 1200,
            opacity: 1,
        }, "-=650")
    }, [])

    function handleCreateClicked() {
        setShowCreate(true)
    }

    function handleCreateCancelled() {
        setShowCreate(false)
    }

    function handleCreateSubmit(properties: RoomProperties) {
        axios.get('/api/create', { params: properties })
            .then((res) => {
                if (res.data.status === "success" && res.data.id != null) {
                    history.push(`/room/${res.data.id}`)
                } else {
                    console.error("Create failed")
                }
            }).catch((error) => {
                console.error(error)
            });
    }

    function handleJoinClicked() {
        setShowJoin(true)
    }

    function handleJoinCancelled() {
        setShowJoin(false)
    }

    function handleJoinSubmit(id: string) {
        history.push(`/room/${id}`)
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                top: '25%',
                bottom: '50%',
                left: '64px',
                right: '64px',
                justifyContent: 'center',
            }}>

                <div style={{ position: "relative", width: "120px", height: "120px" }}>
                    <div className="animCardLogoFront" style={{ position: "absolute", top: "25%", width: "80px", height: "80px", backgroundColor: theme.palette.primary.main, borderRadius: "48px", zIndex: 3 }} />
                    <div className="animCardLogoMiddle logoCard" style={{ opacity: "0", backgroundColor: theme.palette.primary.main, filter: "brightness(70%)", zIndex: 2 }} />
                    <div className="animCardLogoBack logoCard" style={{ opacity: "0", backgroundColor: theme.palette.primary.main, filter: "brightness(50%)", zIndex: 1 }} />
                </div>

                <div style={{ marginLeft: "32px", marginTop: "16px" }}>
                    <Typography className="animAppTitle" variant="h3" style={{ opacity: "0" }}>
                        {appName}
                    </Typography>
                    <Typography className="animAppSubtitle" variant="h5" style={{ opacity: "0" }}>
                        Planning poker for Agile development
                    </Typography>

                    <div className="animButtons" style={{ height: "24px", marginTop: "16px", opacity: "0" }} >
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '24px' }}
                            onClick={handleCreateClicked}>
                            Create
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleJoinClicked}>
                            Join
                        </Button>
                    </div>
                </div>


                <Dialog fullWidth={true} open={showCreate} onClose={handleCreateCancelled} aria-labelledby="form-dialog-title">
                    <CreateDialogContent onCancel={handleCreateCancelled} onCreate={handleCreateSubmit} existingProperties={null} />
                </Dialog>
                <Dialog fullWidth={true} open={showJoin} onClose={handleJoinCancelled} aria-labelledby="form-dialog-title">
                    <JoinDialogContent onCancel={handleJoinCancelled} onJoin={handleJoinSubmit} />
                </Dialog>
            </div>
            <div style={{ position: "absolute", left: "24px", bottom: "24px" }}>
                <Link to={"/changelog"} style={{ textDecoration: "none", color: "inherit" }}>
                    <Typography variant="h6" style={{ fontSize: "16px" }}>
                        {`Version ${appVersion}`}
                    </Typography>
                </Link>
            </div>
        </div>
    )
}
