import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, Select, Switch, TextField, MenuItem, Divider } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Player } from '../../models/ServerModels'

interface IExportResultsProps {
    players: Player[]
    onCancel: () => void
}

enum ExportFormat {
    NewLines = "NewLines",
    Commas = "Commas"
}

function getExportFormatName(type: ExportFormat): string {
    switch (type) {
        case ExportFormat.NewLines: return "New Lines"
        case ExportFormat.Commas: return "Commas"
    }
}

export default function ExportResultsDialogContent({ players, onCancel }: IExportResultsProps) {

    const [includeNames, setIncludeNames] = useState(false)
    const [format, setFormat] = useState(ExportFormat.NewLines)

    function getResultText() {
        var separator = ""
        switch(format) {
            case ExportFormat.NewLines:
                separator = "\n"
                break;
            case ExportFormat.Commas:
                separator = ", "
                break;
        }
        return players.map(player => player.estimate.values
                .map(estimate => (includeNames ? `${player.name}: ` : "") + estimate)
                .join(separator))
            .join(separator)
    }

    function handleCopyClicked() {
        navigator.clipboard.writeText(getResultText())
    }

    function handleFormatChanged(e: React.ChangeEvent<{ value: unknown }>) {
        setFormat(ExportFormat[e.target.value as keyof typeof ExportFormat])
    }

    return (
        <>
            <DialogTitle id="form-dialog-title">Export Result</DialogTitle>
            <DialogContent>
                <FormControl style={{ width: '50%' }}>
                    <InputLabel id="select-format-label">Format</InputLabel>
                    <Select
                        labelId="select-format-label"
                        id="select-format"
                        value={format}
                        onChange={handleFormatChanged}>
                        {Object.keys(ExportFormat).map(type =>
                            <MenuItem key={'menuItem-' + type} value={type}>{getExportFormatName(ExportFormat[type as keyof typeof ExportFormat])}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl style={{ width: '100%', marginTop: "16px" }}>
                        <FormControlLabel
                            id="include-names-check"
                            control={
                                <Switch checked={includeNames} onChange={(e) => setIncludeNames(e.target.checked)} />
                            }
                            label="Include names"
                        />
                </FormControl>
                <Divider style={{marginTop: "16px"}}/>
                <TextField
                    style={{marginTop: "32px"}}
                    autoFocus
                    margin="dense"
                    id="seconds"
                    label={`Result`}
                    fullWidth
                    multiline
                    variant="outlined"
                    maxRows={8}
                    value={getResultText()}
                    InputProps={{
                        readOnly: true
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleCopyClicked}
                    color="primary">
                    Copy to Clipboard
                </Button>
            </DialogActions>
        </>
    )
}