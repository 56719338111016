import React from 'react'
import { Button, Container, IconButton, TextField } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { shallowStringCompare } from '../../util/util'
import { Estimate } from '../../models/ServerModels'

interface IFreeResponseInputProps {
    freeResponseText: string[]
    selectedValues: string[]
    handleReadyClicked: () => void
    handleUpdateClicked: () => void
    setFreeResponseText: (responses: string[]) => void
    makeEstimate: (estimate: Estimate) => void
}

export default function FreeResponseInput({ freeResponseText, selectedValues, handleReadyClicked, handleUpdateClicked,
     setFreeResponseText, makeEstimate }: IFreeResponseInputProps) {

    return (
        <Container>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {freeResponseText.map((response, index) =>

                    <TextField
                        id="outlined-multiline-flexible"
                        label="Response Text"
                        key={`free-response-textfield-${index}`}
                        multiline
                        maxRows={5}
                        inputProps={{ maxLength: 200 }}
                        variant="outlined"
                        fullWidth
                        style={{ margin: "8px", maxWidth: "400px" }}
                        value={freeResponseText[index]}
                        onChange={(e) => {
                            let list = [...freeResponseText]
                            list[index] = e.target.value.replace(/(\r\n|\n|\r)/gm, "")
                            setFreeResponseText(list)
                        }} />
                )}
            </div>

            <Button
                id="free-response-done-button"
                className="actionButton"
                variant="contained"
                color="primary"
                endIcon={selectedValues.length === 0 ? <CheckIcon /> : <ClearIcon />}
                disabled={selectedValues.length === 0 && !freeResponseText.some(v => v.length > 0)}
                onClick={handleReadyClicked}
                style={{ margin: "12px" }}>
                {selectedValues.length === 0 ? "Done" : "Cancel"}
            </Button>

            {selectedValues.length > 0 && !shallowStringCompare(selectedValues, freeResponseText) ?
                <Button
                    id="free-response-update-button"
                    className="actionButton"
                    variant="contained"
                    color="primary"
                    disabled={selectedValues.length === 0 && !freeResponseText.some(v => v.length > 0)}
                    onClick={() => {
                        handleUpdateClicked()
                    }}
                    style={{ margin: "12px" }}>
                    Update
                </Button>
                : <></>}
            <IconButton
                id="free-response-add-button"
                color="primary"
                disabled={freeResponseText.length >= 3}
                onClick={(e) => {
                    let list = [...freeResponseText, ""]
                    setFreeResponseText(list)
                }}>
                <AddIcon />
            </IconButton>

            <IconButton
                id="free-response-delete-button"
                color="primary"
                disabled={freeResponseText.length <= 1}
                onClick={(e) => {
                    let list = [...freeResponseText]
                    list.pop()
                    setFreeResponseText(list)
                }}>
                <RemoveIcon />
            </IconButton>
        </Container>
    )
}