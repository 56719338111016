import React from 'react'
import PickCard from './PickCard'
import { Grid } from '@material-ui/core'
import { RoomStatus } from '../../models/ServerModels'

interface ICardPickerProps {
    possibleCards: string[]
    selectedValue: string
    roomStatus: RoomStatus
    handleValueSelected: (value: string) => void
}

export default function CardPicker({ possibleCards, selectedValue, roomStatus, handleValueSelected }: ICardPickerProps) {
    return (
        <div style={{ display: "inline-block" }}>
            <Grid container alignItems="center" justifyContent="center">
                {possibleCards.map(card =>
                    <Grid key={`card-picker-grid${card}`} item>
                        <PickCard key={`card-picker-${card}`} value={card}
                            isSelected={selectedValue === card && roomStatus === RoomStatus.Estimating}
                            onSelected={handleValueSelected} />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
