import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useSettings } from '../../contexts/SettingsProvider'
import { isBlank } from '../../util/util'

interface ISetTimerDialogContenttProps {
    onCancel: () => void
    onStart: (seconds: number) => void
}

export default function SetTimerDialogContent({ onCancel, onStart }: ISetTimerDialogContenttProps) {
    const [numberText, setNumberText] = useState('')
    const { defaultTimerSeconds, setDefaultTimerSeconds } = useSettings()

    const maxSeconds = 5999 // 99 min, 59 sec

    function handleStartClicked() {
        var seconds = Number.parseInt(numberText)
        setDefaultTimerSeconds(seconds)
        onStart(seconds)
    }

    useEffect(() => {
        setNumberText(defaultTimerSeconds.toString())
    }, [])

    return (
        <>
            <DialogTitle id="form-dialog-title">Set Timer</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Set a timer. Once it the timer hits zero it will automatically show the cards.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="seconds"
                    label={`Seconds (0-${maxSeconds})`}
                    fullWidth
                    type="number"
                    defaultValue={defaultTimerSeconds.toString()}
                    onChange={ (e) => {
                        var number = Number(e.target.value)
                        if (!isNaN(number)) {
                            setNumberText(e.target.value)
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleStartClicked}
                    disabled={isBlank(numberText) || Number(numberText) <= 0 || Number(numberText) > maxSeconds}
                    color="primary">
                    Start
                </Button>
            </DialogActions>
        </>
    )
}