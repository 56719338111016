import React, { useEffect, useState } from 'react'
import { anime } from 'react-anime';
import { Button, TextField, Typography, Box, Container, FormControl } from '@material-ui/core'
import { useSettings } from '../../contexts/SettingsProvider';
import { isBlank } from '../../util/util'

interface IEnterNameProps {
    onFinishEnteringName: (name: string, isSpectator: boolean) => void
}

export default function EnterName({ onFinishEnteringName }: IEnterNameProps) {

    const [nameText, setNameText] = useState("")
    const { name, setName } = useSettings()

    let isSpectator = false

    function onEnterSelected() {
        onFinishEnteringName(nameText, isSpectator)
        setName(nameText)
    }

    function onSpectateSelected() {
        isSpectator = true
        onEnterSelected()
    }

    function handleNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setNameText(e.target.value)
    }

    useEffect(() => {
        anime({
            targets: ".animFadeIn",
            scale: [0.55, 1],
            opacity: [0, 1],
            easing: 'easeInOutSine',
            duration: 200
        })
        setNameText(name)
    }, [])

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: '25%',
            bottom: '50%',
            left: '64px',
            right: '64px',
            justifyContent: 'center',
        }}>

            <Box className="animFadeIn" maxWidth="sm" style={{ textAlign: "center" }}>
                <Box m={4}>
                    <Typography variant="h4">
                        Enter your name
                    </Typography>
                </Box>
                <FormControl style={{ width: "400px" }}>
                    <TextField
                        id="name-field"
                        label="Name"
                        variant="outlined"
                        inputProps={{ maxLength: 30 }}
                        onChange={handleNameChanged}
                        defaultValue={name}
                        style={{ marginBottom: "16px" }} />
                </FormControl>
                <Box m={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: "8px", width: "150px" }}
                        onClick={onEnterSelected}
                        disabled={isBlank(nameText)}>
                        Join
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: "8px", width: "150px" }}
                        onClick={onSpectateSelected}
                        disabled={isBlank(nameText)}>
                        Spectate
                    </Button>
                </Box>
            </Box>

        </div>
    )
}
