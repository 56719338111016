import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useSettings } from '../../contexts/SettingsProvider'
import { isBlank } from '../../util/util'

interface IJoinDialogContentProps {
    onCancel: () => void
    onJoin: (id: string) => void
}

export default function JoinDialogContent({ onCancel, onJoin }: IJoinDialogContentProps) {
    const [idText, setIdText] = useState('')
    const { previousRoomId, shouldSavePreviousRoomId } = useSettings()

    function handleJoinClicked() {
        onJoin(idText)
    }

    function getDefaultIdText(): string {
        return shouldSavePreviousRoomId ? previousRoomId : ''
    }

    useEffect(() => {
        setIdText(getDefaultIdText())
    }, [])

    return (
        <>
            <DialogTitle id="form-dialog-title">Join Room</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Join an existing room.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Room Id"
                    fullWidth
                    onChange={(e) => setIdText(e.target.value)}
                    defaultValue={getDefaultIdText()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleJoinClicked}
                    disabled={isBlank(idText)}
                    color="primary">
                    Join
                </Button>
            </DialogActions>
        </>
    )
}
