import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings'
import HistoryIcon from '@material-ui/icons/History'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import GroupIcon from '@material-ui/icons/Group'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ShareIcon from '@material-ui/icons/Share'
import EmoteIcon from '@material-ui/icons/InsertEmoticon'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import FileCopyIcon from "@material-ui/icons/FileCopy"
import TimerIcon from '@material-ui/icons/Timer'
import PaletteIcon from '@material-ui/icons/Palette'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import ListIcon from '@material-ui/icons/List'
import KeyboardIcon from "@material-ui/icons/Keyboard"
import ZoomIcon from "@material-ui/icons/ZoomIn"
import ImageIcon from "@material-ui/icons/Image"
import BarChartIcon from "@material-ui/icons/BarChart"
import { appName } from '../util/util'

import { Card, CardContent, Grid } from '@material-ui/core';
import { anime } from 'react-anime';

export default function Changelog() {
    const dogArt = require('../assets/dogs-playing-poker.png');

    useEffect(() => {
        document.title = `${appName} - What's New?`
    }, [])

    class ChangelogItem {
        constructor(public title: string, public icon: JSX.Element) { }
    }

    let items_2_2_0: ChangelogItem[] = [
        new ChangelogItem("Copy Result", <FileCopyIcon />)
    ]

    let items_2_1_0: ChangelogItem[] = [
        new ChangelogItem("Timers", <TimerIcon />)
    ]

    let items_2_0_0: ChangelogItem[] = [
        new ChangelogItem("New Animations", <VisibilityIcon />),
        new ChangelogItem("Themed Logo Color", <PaletteIcon />),
        new ChangelogItem("Spectator Mode", <GroupIcon />),
        new ChangelogItem("Mean/Median Results", <BarChartIcon />),
        new ChangelogItem("Unanimous Vote Indicator", <GroupWorkIcon />),
        new ChangelogItem("Edit Room", <SettingsIcon />),
        new ChangelogItem("Custom Voting Schemes", <ListIcon />),
        new ChangelogItem("Anonymous Mode", <VisibilityOffIcon />),
        new ChangelogItem("Lock Room", <LockIcon />),
        new ChangelogItem("Vote History", <HistoryIcon />),
        new ChangelogItem("Copy Link", <ShareIcon />),
        new ChangelogItem("Emote Reactions", <EmoteIcon />),
        new ChangelogItem("Free Response Mode", <KeyboardIcon />),
        new ChangelogItem("Click Card to Enlarge", <ZoomIcon />),
        new ChangelogItem("Animated Backgrounds", <ImageIcon />),
        new ChangelogItem("Changelog Page", <ListIcon />),
    ]

    useEffect(() => {
        anime({
            targets: ".animateGridItem",
            scale: [0, 1],
            easing: 'easeOutElastic',
            duration: 750,
            delay: anime.stagger(100),
        })
    }, [])

    function renderVersion(number: string, items: ChangelogItem[]) {
        return <div style={{ margin: "24px" }}>
            <Typography variant="h6"> {number} </Typography>
            {renderItems(items)}
         </div>
    }

    function renderItems(items: ChangelogItem[]) {
        return <div style={{ marginLeft: "20%", marginRight: "20%" }}>
        <Grid container justifyContent="center" spacing={5}>
            {items.map(item =>
                <Grid className="animateGridItem" item xs="auto">
                    <Card>
                        <CardContent style={{ display: "flex" }}>
                            {item.icon}
                            <Typography style={{ marginLeft: "8px", marginRight: "16px" }}>
                                {item.title}
                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    </div>
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{

                width: "100%",
                margin: "48px",
            }}>
                <Typography variant="h5"> What's New? </Typography>
            
                {renderVersion("2.2.0", items_2_2_0)}
                {renderVersion("2.1.0", items_2_1_0)}
                {renderVersion("2.0.0", items_2_0_0)}
            </div>
        </div>
    )
}
