import { Card, CardContent, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { anime } from 'react-anime';

interface IThemeItemProps {
    themeKey: string,
    theme: any,
    isSelected: boolean,
    onSelected: (key: string) => void
}

export default function ThemeItem({ themeKey, theme, isSelected, onSelected }: IThemeItemProps) {

    useEffect(() => {
        anime({
            targets: ".themeItemSelected",
            scale: 1.2,
            duration: 400
        })
        anime({
            targets: ".themeItem",
            scale: 1,
            duration: 400
        })
    }, [isSelected])

    function handleClicked() {
        onSelected(themeKey)
    }

    return (
        <Card
            className={isSelected ? 'themeItemSelected' : 'themeItem'}
            style={{ width: '150px', margin: '16px', backgroundColor: theme.theme.overrides.MuiCssBaseline.bgColor }}
            onClick={handleClicked}>

            <CardContent>
                <Typography variant="body1" style={{ color: theme.theme.palette.text.primary }}>
                    {theme.name}
                </Typography>
                <div style={{ backgroundColor: theme.theme.palette.primary.main, height: "8px", borderRadius: "4px" }} />
                <div style={{ backgroundColor: theme.theme.palette.secondary.main, height: "8px", borderRadius: "4px", marginTop: '4px' }} />
            </CardContent>
        </Card>
    )
}
