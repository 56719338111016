import { Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { anime } from 'react-anime';
import { Color, ColorPicker } from 'material-ui-color';
import PokerCard from '../room/PokerCard'
import { CardImage, cardImageMap, CardImageType } from '../../themes/theme';

interface ICustomCardBuilderProps {
    customCardColor: string,
    setCustomCardColor: (color: string) => void
    useCustomCardColor: boolean
    setUseCustomCardColor: (useColor: boolean) => void
    cardImage: string
    setCardImage: (cardImage: string) => void
    customCardImage: boolean
    setCustomCardImage: (customCarImage: boolean) => void
}

export default function CustomCardBuilder({ customCardColor, setCustomCardColor, useCustomCardColor, setUseCustomCardColor,
    cardImage, setCardImage, customCardImage, setCustomCardImage }: ICustomCardBuilderProps) {

    function handleColorTypeChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setUseCustomCardColor(e.target.value === 'custom')
    }

    function handleCustomColorChanged(color: Color) {
        setCustomCardColor(`#${color.hex}`)
    }

    function handleImageCardClicked(key: string) {
        setCardImage(key)
    }

    function renderImagesList() {
        var useImages = Array.from(cardImageMap.entries())
        useImages.unshift(['none', new CardImageType(new CardImage("", 0, 0), "None")])
        return useImages.map(list =>
            <Grid key={'grid-' + list[0]} item>
                <PokerCard key={list[0]} selectKey={list[0]} value={"8"} hasEstimated={true} name={list[1].name}
                    image={list[1].image} onClick={handleImageCardClicked} isSelected={cardImage === list[0]} invertFlip={false}
                    inGame={false} isAnonymous={false} isHorizontal={false} emote={""} isZoomed={false} onEmoteFinished={function (): void { }} />
            </Grid>
        )
    }

    useEffect(() => {
        anime({
            targets: ".poker-card-selected",
            scale: 1.1,
            duration: 400
        })
        anime({
            targets: ".poker-card-unselected",
            scale: 1,
            duration: 400
        })
    }, [cardImage])

    return (
        <Container>
            <Container>
                <Grid container alignItems="center" justifyContent="center" style={{ marginBottom: "48px" }}>
                    <Grid item>
                        <PokerCard key='frontCard' value={"8"} hasEstimated={true} name={'Back'}
                            invertFlip={false} image={null} isSelected={false} inGame={false} isAnonymous={false} isHorizontal={false} emote={""}
                            onEmoteFinished={function (): void { }} isZoomed={false} selectKey={''} onClick={function (key: string): void { }} />
                    </Grid>
                    <Grid item>
                        <PokerCard key='backCard' value={"8"} hasEstimated={true} name={'Front'} invertFlip={true}
                            image={null} isSelected={false} inGame={false} isAnonymous={false} isHorizontal={false} emote={""}
                            onEmoteFinished={function (): void { }} isZoomed={false} selectKey={''} onClick={function (key: string): void { }} />
                    </Grid>
                </Grid>
            </Container>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginBottom: '24px' }}>
                <Container>
                    <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
                        <FormLabel component="legend">Card Color</FormLabel>
                        <RadioGroup aria-label="cardColor" name="cardColor" value={useCustomCardColor ? 'custom' : 'theme'} onChange={handleColorTypeChanged}>
                            <FormControlLabel value="theme" control={<Radio />} label="Derived from Theme" />
                            <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                        </RadioGroup>
                    </FormControl>
                    {useCustomCardColor ?
                        <ColorPicker
                            value={customCardColor}
                            onChange={handleCustomColorChanged}
                            disableAlpha />
                        : <></>
                    }
                </Container>
            </div>
            <Typography variant="h6">Card Image</Typography>
            <Container>
                <Grid container alignItems="center" justifyContent="center" style={{ marginTop: '48px', marginBottom: '64px' }}>
                    {renderImagesList()}
                </Grid>
            </Container>
        </Container>
    )
}
