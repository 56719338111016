import { MuiThemeProvider } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import useLocalStorage from '../hooks/UseLocalStorage';
import { themeMap, buildTheme, defaultCustomThemeParams, CustomThemeParams } from '../themes/theme'

interface ISettingsContext {
    theme: string
    setTheme: (theme: string) => void
    customThemeParams: CustomThemeParams
    setCustomThemeParams: (params: CustomThemeParams) => void
    customCardColor: string
    setCustomCardColor: (color: string) => void
    useCustomCardColor: boolean,
    setUseCustomCardColor: (color: boolean) => void
    cardImage: string
    setCardImage: (image: string) => void
    customCardImage: boolean
    setCustomCardImage: (customImage: boolean) => void
    name: string
    setName: (name: string) => void
    autoUseName: boolean
    setAutoUseName: (autoUseName: boolean) => void
    previousRoomId: string
    setPreviousRoomId: (id: string) => void
    shouldSavePreviousRoomId: boolean
    setShouldSavePreviousRoomId: (id: boolean) => void
    getCustomTheme: (theme: CustomThemeParams) => any
    backgroundType: BackgroundType,
    setBackgroundType: (type: BackgroundType) => void
    defaultTimerSeconds: number,
    setDefaultTimerSeconds: (seconds: number) => void
}

const SettingsContext = React.createContext({} as ISettingsContext)

export function useSettings() {
    return useContext(SettingsContext)
}

interface IThemeProviderProps {
    children: JSX.Element | JSX.Element[]
}

export enum BackgroundType {
    None, Connected, Snow, Stars, FallLeaves, Bubbles
}

export function getBackgroundTypeName(type: BackgroundType) {
    switch (type) {
        case BackgroundType.None: return "None"
        case BackgroundType.Connected: return "Connected"
        case BackgroundType.Snow: return "Snow"
        case BackgroundType.Stars: return "Stars"
        case BackgroundType.FallLeaves: return "Fall Leaves"
        case BackgroundType.Bubbles: return "Bubbles"
    }
}

export default function ThemeProvider({ children }: IThemeProviderProps) {

    function getCustomTheme(params: CustomThemeParams = customThemeParams) {
        return buildTheme(params.isDark, params.primary, params.secondary, params.background, params.background)
    }

    function getThemeFromKey(key: string, customThemeParams: CustomThemeParams) {
        if (key == 'custom') {
            return getCustomTheme()
        }
        var useKey = key
        if (!themeMap.has(key)) {
            useKey = 'dark'
        }
        const newTheme = themeMap.get(key)!.theme
        return newTheme
    }

    const [theme, setTheme] = useLocalStorage('theme', 'dark')
    const [customThemeParams, setCustomThemeParams] = useLocalStorage('customTheme', defaultCustomThemeParams)
    const [customCardColor, setCustomCardColor] = useLocalStorage('customCardColor', 'red')
    const [useCustomCardColor, setUseCustomCardColor] = useLocalStorage('useCustomCardColor', false)
    const [cardImage, setCardImage] = useLocalStorage('cardImage', 'none')
    const [customCardImage, setCustomCardImage] = useLocalStorage('customCardImage', '')
    const [name, setName] = useLocalStorage('name', '')
    const [autoUseName, setAutoUseName] = useLocalStorage('autoUseName', false)
    const [previousRoomId, setPreviousRoomId] = useLocalStorage('previousRoomId', '')
    const [shouldSavePreviousRoomId, setShouldSavePreviousRoomId] = useLocalStorage('savePreviousRoomId', true)
    const [backgroundType, setBackgroundType] = useLocalStorage("backgroundType", BackgroundType.None)
    const [defaultTimerSeconds, setDefaultTimerSeconds] = useLocalStorage("defaultTimerSeconds", 30)
    const useTheme = getThemeFromKey(theme, customThemeParams)

    const value: ISettingsContext = {
        theme: theme,
        setTheme: setTheme,
        customThemeParams: customThemeParams,
        setCustomThemeParams: setCustomThemeParams,
        customCardColor: customCardColor,
        setCustomCardColor: setCustomCardColor,
        useCustomCardColor: useCustomCardColor,
        setUseCustomCardColor: setUseCustomCardColor,
        cardImage: cardImage,
        setCardImage: setCardImage,
        customCardImage: customCardImage,
        setCustomCardImage: setCustomCardImage,
        name: name,
        setName: setName,
        autoUseName: autoUseName,
        setAutoUseName: setAutoUseName,
        previousRoomId: previousRoomId,
        setPreviousRoomId: setPreviousRoomId,
        shouldSavePreviousRoomId: shouldSavePreviousRoomId,
        setShouldSavePreviousRoomId: setShouldSavePreviousRoomId,
        backgroundType: backgroundType,
        setBackgroundType: setBackgroundType,
        defaultTimerSeconds: defaultTimerSeconds,
        setDefaultTimerSeconds: setDefaultTimerSeconds,
        getCustomTheme: getCustomTheme
    }

    return (
        <SettingsContext.Provider value={value}>
            <MuiThemeProvider theme={useTheme}>
                {children}
            </MuiThemeProvider>
        </SettingsContext.Provider>
    )
}