import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import { cyan, deepOrange, deepPurple, lime, pink } from '@material-ui/core/colors';

export function buildTheme(isDark: boolean, primary: string, secondary: string, background: string, paper: string) {
  return (createTheme as any)({
    palette: {
      type: isDark ? 'dark' : 'light',
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
    overrides: {
      MuiCssBaseline: {
        bgColor: background,
        '@global': {
          body: {
            backgroundColor: background,
          }
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: paper
        }
      }
    }
  })
}

export class CardImage {
  constructor(public url: string, public width: number, public height: number) { }
}

export const checkerboardImage = new CardImage(require('../assets/cards/checkerboard.png'), 80, 80)
export const starImage = new CardImage(require('../assets/cards/star.png'), 80, 120)
export const dotsImage = new CardImage(require('../assets/cards/dots.png'), 80, 120)
export const fallImage = new CardImage(require('../assets/cards/fall.png'), 80, 120)
export const oceanImage = new CardImage(require('../assets/cards/ocean.png'), 80, 120)
export const hanabiImage = new CardImage(require('../assets/cards/hanabi.png'), 80, 120)
export const snakeImage = new CardImage(require('../assets/cards/snake.png'), 80, 120)

export class CustomThemeParams {
  constructor(public isDark: boolean, public primary: string, public secondary: string, public background: string, public paper: string) { }
}

export const defaultCustomThemeParams = { isDark: true, primary: red[500], secondary: green[500], background: '#222', paper: '#222' }
export const darkTheme = buildTheme(true, blue[500], red[500], "#222", "#222")
export const lightTheme = buildTheme(false, blue[600], red[800], '#FFF', '#FFF')
export const notTooBrightTheme = buildTheme(false, blue[600], red[800], '#CFCFCF', '#E6E6E6')
export const mirageTheme = buildTheme(true, purple[500], pink[500], '#25274d', '#25274d')
export const leafTheme = buildTheme(true, '#74D334', '#398A3A', '#21351C', '#21351C')
export const autumnTheme = buildTheme(true, '#E09119', '#E2B147', '#cf8517', '#A13219')
export const oceanTheme = buildTheme(true, '#22B8CF', blue[500], '#0B7285', '#0B7285')
export const linenTheme = buildTheme(false, orange[400], orange[500], '#e7e3d4', '#e7e3d4')
export const blueTheme = buildTheme(true, blue[500], blue[300], '#2f4454', '#2f4454')
export const pitchBlackTheme = buildTheme(true, '#919090', '#ECF4F4', 'black', 'black')
export const slateTheme = buildTheme(true, '#88ABB8', '#687387', '#606060', '#606060')
export const cottonCandyTheme = buildTheme(false, '#D91AD9', '#7B1B7E', '#F4D2F2', '#F4D2F2')

export const chartColors = [
  blue[500], red[500], green[500], yellow[500], purple[500], orange[500], cyan[500], pink[500], lime[500], deepPurple[500], deepOrange[500]
]

export const themeList = [
  { key: 'dark', data: { theme: darkTheme, name: 'Dark' } },
  { key: 'blue', data: { theme: blueTheme, name: 'Blue' } },
  { key: 'mirage', data: { theme: mirageTheme, name: 'Mirage' } },
]

export const themeMap = new Map([
  ['dark', { theme: darkTheme, name: 'Dark' }],
  ['light', { theme: lightTheme, name: 'Light' }],
  ['notTooBright', { theme: notTooBrightTheme, name: 'Not Too Bright' }],
  ['mirage', { theme: mirageTheme, name: 'Mirage' }],
  ['leaf', { theme: leafTheme, name: 'Leaf' }],
  ['autumn', { theme: autumnTheme, name: 'Autumn' }],
  ['ocean', { theme: oceanTheme, name: 'Ocean' }],
  ['linen', { theme: linenTheme, name: 'Linen' }],
  ['blue', { theme: blueTheme, name: 'Blue' }],
  ['pitchBlack', { theme: pitchBlackTheme, name: 'Pitch Black' }],
  ['slate', { theme: slateTheme, name: 'Slate' }],
  ['cottonCandy', { theme: cottonCandyTheme, name: 'Cotton Candy' }]
])

export class CardImageType {
  constructor(public image: CardImage, public name: string) { }
}

export const cardImageMap = new Map([
  ['checkerboard', new CardImageType(checkerboardImage, 'Checkerboard')],
  ['star', new CardImageType(starImage, 'Stars')],
  ['dots', new CardImageType(dotsImage, 'Dots')],
  ['fall', new CardImageType(fallImage, 'Fall')],
  ['ocean', new CardImageType(oceanImage, 'Ocean')],
  ['hanabi', new CardImageType(hanabiImage, 'Hanabi')],
  ['snake', new CardImageType(snakeImage, 'Snake')]
])