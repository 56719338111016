import React, { useState, useEffect, useRef } from 'react'
import { anime } from 'react-anime';
import { useRoom, roomStatuses } from '../../contexts/RoomProvider'
import { Button, Box, Container, Typography, Grid, Dialog, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Snackbar, SnackbarContent, Divider } from '@material-ui/core'
import { format } from 'date-fns'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings'
import HistoryIcon from '@material-ui/icons/History'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import GroupIcon from '@material-ui/icons/Group'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ShareIcon from '@material-ui/icons/Share'
import EmoteIcon from '@material-ui/icons/InsertEmoticon'
import TimerIcon from "@material-ui/icons/Timer"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import PokerCard from './PokerCard'
import CardPicker from './CardPicker';
import ResultsChart from './ResultsChart';
import CreateDialogContent from '../join/CreateDialogContent';
import { Estimate, GameType, RoomProperties, RoomState, RoomStatus } from '../../models/ServerModels';
import EventHistoryDialogContent from './EventHistoryDialogContent';
import FreeResponseInput from './FreeResponseInput';
import SetTimerDialogContent from './SetTimerDialogContent';
import ExportResultsDialogContent from './ExportResultDialog';

export class EventLog {
    constructor(public dateString: string, public eventString: string) { }
}

export default function RoomPlaying() {
    const { roomState, roomProperties, previousRoomState, previousRoomProperties, player, makeEstimate, showCards, newGame, editRoom, setTimer, lockRoom, toggleSpectator, playerEmotes, performEmote, clearEmote } = useRoom()
    const [selectedValues, setSelectedValues] = useState<string[]>([])
    const [disableButton, setDisableButton] = useState(false)
    const [hideChart, setHideChart] = useState(false)
    const [hidePickCards, setHidePickCards] = useState(false)
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
    const [emoteMenuAnchor, setEmoteMenuAnchor] = useState<null | HTMLElement>(null)
    const [showEdit, setShowEdit] = useState(false)
    const [showSetTimer, setShowSetTimer] = useState(false)
    const [showExportResults, setShowExportResults] = useState(false)
    const [showHistory, setShowHistory] = useState(false)
    const [eventLog, setEventLog] = useState<EventLog[]>([])
    const [linkSnackOpen, setLinkSnackOpen] = useState(false)
    const [zoomedCard, setZoomedCard] = useState("")
    const zoomedCardRef = useRef<HTMLDivElement>(null)
    const [currentTime, setCurrentTime] = useState(Date.now())
    const [timerStartTimeOffset, setTimerStartTimeOffset] = useState<number | null>(null)
    const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(null)

    // TODO - Replace this.  Cards aren't flipping because previous state is being updated to the current state before this is rendered
    const [hasShownInitialState, setHasShownInitialState] = useState(false)

    const menuOpen = Boolean(menuAnchor)
    const emoteMenuOpen = Boolean(emoteMenuAnchor)

    const [freeResponseText, setFreeResponseText] = useState([""])

    const isLocked = roomState.lockOwnerId !== null && roomState.lockOwnerId !== (player?.id ?? "")

    const emoteOptions = ["👍", "👎", "👏", "💖", "😀", "⌛"]

    function handleEditClicked() {
        handleMenuClose()
        setShowEdit(true)
    }

    function handleEditCancelled() {
        setShowEdit(false)
    }

    function handleEditSubmit(properties: RoomProperties) {
        editRoom(properties)
        setShowEdit(false)
    }

    function handleHistoryClicked() {
        handleMenuClose()
        setShowHistory(true)
    }

    function handleHistoryCancelled() {
        setShowHistory(false)
    }

    function handleCopyLinkClicked() {
        handleMenuClose()
        navigator.clipboard.writeText(window.location.href)
        setLinkSnackOpen(true)
    }

    function handleExportResultClicked() {
        setShowExportResults(true)
        handleMenuClose()
    }

    function handleExportResultCancelled() {
        setShowExportResults(false)
    }

    function handleLockClicked() {
        handleMenuClose()
        lockRoom()
    }

    function handleSpectateClicked() {
        handleMenuClose()
        toggleSpectator()
    }

    function handleSetTimerClicked() {
        handleMenuClose()
        setShowSetTimer(true)
    }

    function handleSetTimerSubmit(seconds: number) {
        setTimer(seconds)
        setShowSetTimer(false)
    }

    function handleSetTimerCancelled() {
        setShowSetTimer(false)
    }

    function handleLinkSnackClose() {
        setLinkSnackOpen(false)
    }

    function handleEmoteClicked(emote: string) {
        setEmoteMenuAnchor(null)
        performEmote(emote)
    }

    function logEvent(event: string) {
        let dateString = format(new Date(), "h:mm a")
        let item = new EventLog(dateString, event)
        let newLogList = eventLog
        newLogList.unshift(item)
        setEventLog(newLogList)
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (!(zoomedCardRef?.current?.contains(event.target as Node) ?? false)) {
            setZoomedCard("")
        }
    };

    useEffect(() => {
        // Initially hide the chart
        const showChart = roomProperties.gameType !== GameType.FreeResponse && roomState.status === roomStatuses.results
        setHideChart(!showChart)
        animateChartVisible(showChart)

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [])

    useEffect(() => {
        console.log("statestate")
        if (!hasShownInitialState || previousRoomState?.status !== roomState.status) {
            setHasShownInitialState(true)

            if (roomState.status === RoomStatus.Results && roomProperties.gameType !== GameType.FreeResponse) {
                let estimateString = roomState.players.filter(p => p.estimate.values.length > 0).map(p => (!roomProperties.anonymous ? (p.name + ": ") : "") + p.estimate.values[0]).join(", ")
                logEvent(estimateString)
            }

            flipCards(roomState.status !== roomStatuses.estimating)
            if (roomState.status === roomStatuses.estimating) {
                // Hide chart until it fades in so its animation starts later.
                setHideChart(true)
                animateChartVisible(false)
                setTimeout(function () {
                    setHidePickCards(false)
                }, 500)
            } else {
                setZoomedCard("")

                setTimeout(function () {
                    setHideChart(false)
                }, 800)
                setHidePickCards(true)
            }

            setTimeout(function () {
                animateChartVisible(roomProperties.gameType !== GameType.FreeResponse && roomState.status === roomStatuses.results)
                if (roomState.status === roomStatuses.results) {
                    resetUserInput()
                    anime({
                        targets: ".actionButton",
                        opacity: 1,
                        easing: 'easeInOutSine',
                        duration: 400
                    })
                }
            }, 600)

            setDisableButton(true)
            setTimeout(function () {
                setDisableButton(false)
            }, 1200)
        }
        if (!hasShownInitialState || roomState.timer !== previousRoomState?.timer) {
            if (timerInterval != null) {
                clearInterval(timerInterval)
                setTimerInterval(null)
            }

            if (roomState.timer == null) {
                setTimerStartTimeOffset(null)
            } else {
                if (timerStartTimeOffset == null) {
                    var offset = Date.now() - roomState.timer.serverStartTimeMillis
                    setTimerStartTimeOffset(offset)
                }
                setTimerInterval(setInterval(() => updateTime(), 200))
            }
        }
    }, [roomState])

    useEffect(() => {
        if (previousRoomProperties?.gameType !== roomProperties.gameType) {
            resetUserInput()
        }
    }, [roomProperties])

    function resetUserInput() {
        setSelectedValues([])
        setFreeResponseText([""])
    }

    function animateChartVisible(visible: boolean, duration: number = 400) {
        anime({
            targets: ".resultsChart",
            opacity: visible ? 1 : 0,
            height: visible ? 200 : 0,
            easing: 'easeInOutSine',
            duration: duration
        })
    }

    function flipCards(isFront: boolean) {
        console.log("Flipping cards: " + isFront + " " + roomProperties.gameType)
        let isHorizontal = roomProperties.gameType === GameType.FreeResponse
        anime({
            targets: ".poker-card-game", // Only animate actual game cards
            scale: [{ value: 1 }, { value: 1.05 }, { value: 1, delay: 150 }],
            rotateY: { value: isFront && !isHorizontal ? 180 : 0 },
            rotateX: { value: isFront && isHorizontal ? 180 : 0 },
            easing: 'easeInOutSine',
            delay: anime.stagger(100, { from: 'center' }, { easing: 'easeOutQuad' }),
            duration: 400
        })
    }

    function handleValueSelected(value: string | null) {
        const firstValue = selectedValues.length > 0 ? selectedValues[0] : ""
        const newValue = (value !== firstValue ? value : "") ?? ""
        setSelectedValues([newValue])
        makeEstimate(new Estimate(player!.id, [newValue]))
    }

    function onShowCardsPressed() {
        showCards()
    }

    function onNewGamePressed() {
        newGame()
    }

    function updateTime() {
        setCurrentTime(Date.now())
    }

    function handleFreeResponseReadyClicked() {
        let newSelectedValues = selectedValues.length === 0 ? freeResponseText.filter(v => v.length > 0) : []
        setSelectedValues(newSelectedValues)
        makeEstimate(new Estimate(player!.id, newSelectedValues))
    }

    function handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
        setMenuAnchor(event.currentTarget);
    }

    function handleMenuClose() {
        setMenuAnchor(null);
    }

    function renderActionButton() {
        if (roomState.status === roomStatuses.estimating) {
            return <Button
                className="actionButton"
                variant="contained"
                color="primary"
                disabled={isLocked || !roomState.players.some(player => player.hasEstimated)}
                onClick={onShowCardsPressed}>
                Show Cards
            </Button>
        } else {
            return <Button variant="contained" color="primary" onClick={onNewGamePressed} disabled={isLocked || disableButton}> New Game </Button>
        }
    }

    function renderTimer() {
        var timer = roomState.timer
        if (timer == null) { return <></> }
        var secondsRemaining = (timer.serverStartTimeMillis + (timerStartTimeOffset ?? 0) + timer.totalTimeMillis - currentTime) / 1000
        // Because we add 1 it's possible for it to briefly show as over the total time, don't display any higher than total time.
        secondsRemaining = Math.min(secondsRemaining + 1, timer.totalTimeMillis / 1000)

        if (secondsRemaining < 0) { return <></>}

        var minutes = Math.floor(secondsRemaining / 60)
        var seconds = (Math.floor(secondsRemaining) % 60)
        var secondsString = minutes > 0 && seconds < 10 ? "0" + seconds : seconds
        var displayString = minutes > 0 ? `${minutes} : ${secondsString}` : secondsString
        return <Typography variant="h5" style={{ height: "48px", lineHeight: "48px" }}> { displayString } </Typography>
    }

    let spectators = roomState.players.filter(p => p.isSpectator)
    let spectatorListText = roomProperties.anonymous ? ("(" + spectators.length + ")") : spectators.map(s => s.name).join(", ")

    return (
        <Container style={{ height: "80vh", textAlign: "center" }}>
            <div style={{ display: "flex", marginBottom: "16px", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="h5" style={{ height: "48px", lineHeight: "48px" }}>
                    {roomProperties.name}
                    <div style={{ display: "inline-block", position: "absolute", marginLeft: "16px" }}>
                        <IconButton color="inherit" onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        {!roomProperties.disableEmotes && !(player?.isSpectator ?? false) ?
                            <IconButton color="inherit" onClick={(e) => setEmoteMenuAnchor(e.currentTarget)}>
                                <EmoteIcon />
                            </IconButton> : <></>
                        }

                    </div>
                </Typography>

            </div>

            <Box className="resultsChart" justifyContent="center" display="flex" alignItems="center">
                {roomProperties.gameType !== GameType.FreeResponse && !hideChart && roomState.status === roomStatuses.results && roomState.players.filter(player => player.estimate.values.length > 0).length > 0 ?
                    <ResultsChart players={roomState.players} /> : <></>
                }
            </Box>

            <Box m={2} style={{ marginTop: "32px" }}>
                {renderActionButton()}
            </Box>

            <Typography variant="h5" style={{ height: "48px", lineHeight: "48px" }}> 
                { renderTimer() } 
            </Typography>

            {spectators.length > 0 ?
                <div>
                    <Typography variant="h6">Spectators: </Typography>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="body1">
                            {
                                spectatorListText
                            }
                        </Typography>
                    </div>
                </div> : <></>
            }

            <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '40vh', marginBottom: "16px" }}>
                {roomState.players.filter(player => !player.isSpectator).map(player => (
                    <React.Fragment key={`poker-card-player-div-${player.id}`} >
                        {(player.estimate.values.length === 0 ? [""] : player.estimate.values).map((estimate: string, index: number) =>
                            <Grid ref={zoomedCard === `${player.id}${index}` ? zoomedCardRef : null} key={`grid-card-${player.id}-${index}`} item style={{ zIndex: zoomedCard === `${player.id}${index}` ? 10 : 5 }}>
                                <PokerCard
                                    key={`poker-card-${index}`}
                                    selectKey={`${player.id}${index}`} value={estimate} hasEstimated={player.hasEstimated} name={player.name}
                                    inGame={true} isAnonymous={roomProperties.anonymous} isHorizontal={roomProperties.gameType === GameType.FreeResponse}
                                    emote={index === 0 ? (playerEmotes.get(player.id) ?? "") : ""}
                                    onEmoteFinished={function (): void { clearEmote(player.id) }} invertFlip={false}
                                    isZoomed={zoomedCard === `${player.id}${index}`}
                                    image={null} isSelected={false} onClick={(key: string) => {
                                        if (roomState.status === RoomStatus.Results) {
                                            setZoomedCard(`${player.id}${index}`)
                                        }
                                    }} />
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
            </Grid>

            {!(player?.isSpectator ?? false) && roomProperties.gameType == GameType.PickCards && !hidePickCards && roomState.status === roomStatuses.estimating ?
                <CardPicker
                    possibleCards={roomProperties.votingScheme}
                    selectedValue={selectedValues.length > 0 ? selectedValues[0] : ""}
                    roomStatus={roomState.status}
                    handleValueSelected={handleValueSelected} />
                : <></>
            }

            {!(player?.isSpectator ?? false) && roomProperties.gameType == GameType.FreeResponse && !hidePickCards && roomState.status === roomStatuses.estimating ?
                <FreeResponseInput
                    freeResponseText={freeResponseText}
                    selectedValues={selectedValues}
                    handleReadyClicked={handleFreeResponseReadyClicked}
                    handleUpdateClicked={() => {
                        setSelectedValues(freeResponseText)
                        makeEstimate(new Estimate(player!.id, freeResponseText))
                    }}
                    setFreeResponseText={setFreeResponseText}
                    makeEstimate={makeEstimate}
                />
                : <></>
            }

            <Dialog fullWidth={true} open={showEdit} onClose={handleEditCancelled} aria-labelledby="form-dialog-title">
                <CreateDialogContent onCancel={handleEditCancelled} onCreate={handleEditSubmit} existingProperties={roomProperties} />
            </Dialog>

            <Dialog fullWidth={true} open={showHistory} onClose={handleHistoryCancelled} aria-labelledby="form-dialog-title">
                <EventHistoryDialogContent events={eventLog} />
            </Dialog>

            <Dialog fullWidth={true} open={showSetTimer} onClose={handleSetTimerCancelled} aria-labelledby="form-dialog-title">
                    <SetTimerDialogContent onCancel={handleSetTimerCancelled} onStart={handleSetTimerSubmit} />
                </Dialog>

            <Dialog fullWidth={true} open={showExportResults} onClose={handleExportResultCancelled} aria-labelledby="form-dialog-title">
                <ExportResultsDialogContent players={roomState.players} onCancel={handleExportResultCancelled} />
             </Dialog>

            <Snackbar open={linkSnackOpen} autoHideDuration={6000} onClose={handleLinkSnackClose}>
                <SnackbarContent message={"Link copied!"} />
            </Snackbar>

            <Menu
                id="room-menu"
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>

                <MenuItem onClick={handleEditClicked} disabled={isLocked} style={{ display: 'flex', justifyContent: "space-between", width: "200px" }}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit Room</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleSetTimerClicked} disabled={isLocked || roomState.status == RoomStatus.Results} style={{ display: 'flex', justifyContent: "space-between", width: "200px" }}>
                    <ListItemIcon>
                        <TimerIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText> Set Timer </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleLockClicked} disabled={isLocked} style={{ display: 'flex', justifyContent: "space-between", width: "200px" }}>
                    <ListItemIcon>
                        {roomState.lockOwnerId === null ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText> {roomState.lockOwnerId === null ? "Lock Controls" : "Unlock Controls"}</ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleExportResultClicked} disabled = {roomState.status != RoomStatus.Results} style={{ display: 'flex', justifyContent: "space-between", width: "200px" }}>
                    <ListItemIcon>
                        <FileCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Export Result</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleHistoryClicked} disabled={eventLog.length === 0} style={{ display: 'flex', justifyContent: "space-between", width: "200px" }}>
                    <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>History</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCopyLinkClicked} style={{ display: 'flex', justifyContent: "space-between", width: "200px" }}>
                    <ListItemIcon>
                        <ShareIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copy Link</ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleSpectateClicked} style={{ display: 'flex', justifyContent: "space-between", width: "200px" }}>
                    <ListItemIcon>
                        {player?.isSpectator ?? false ? <GroupAddIcon fontSize="small" /> : <GroupIcon fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText> {player?.isSpectator ?? false ? "Join" : "Spectate"}</ListItemText>
                </MenuItem>
            </Menu>

            <Menu
                id="emote-menu"
                anchorEl={emoteMenuAnchor}
                open={emoteMenuOpen}
                onClose={() => setEmoteMenuAnchor(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>

                <Grid container spacing={0} style={{ width: "225px", height: "150px" }}>
                    {emoteOptions.map((emote, index) =>
                        <Grid item xs={4} style={{ textAlign: "center" }} key={`emote-icon-${index}`}>
                            <IconButton color="inherit" key={`emote-icon-button-${index}`} onClick={(e) => handleEmoteClicked(emote)} style={{ fontSize: "24px", textAlign: "center" }}>
                                {emote}
                            </IconButton>
                        </Grid>
                    )}
                </Grid>

            </Menu>

        </Container>
    )
}
