import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core'
import { useSettings } from '../../contexts/SettingsProvider';
import { anime } from 'react-anime';
import { CardImage, cardImageMap } from '../../themes/theme';

interface IPokerCardProps {
    value: string,
    hasEstimated: boolean,
    name: string
    invertFlip: boolean,
    image: CardImage | null
    isSelected: boolean,
    inGame: boolean,
    isAnonymous: boolean,
    isHorizontal: boolean,
    emote: string,
    isZoomed: boolean,
    onEmoteFinished: () => void
    selectKey: string
    onClick: (key: string) => void
}

export default function PokerCard({ value, hasEstimated, name, invertFlip = false, image = null, isSelected = false, inGame = false, 
        isAnonymous = false, isHorizontal = false, emote = "", isZoomed = false, onEmoteFinished, selectKey, onClick }: IPokerCardProps) {
            
    const unselectedCssClass = (!hasEstimated ? " card-unselected" : "")
    const theme = useTheme<Theme>()
    const [prevEmote, setPrevEmote] = useState("")
    const { customCardColor, useCustomCardColor, cardImage } = useSettings()

    const useCardImage = image != null ? image : (cardImage != 'none' ? cardImageMap.get(cardImage)?.image : null)

    let cardContainerClassName = isHorizontal ? "card-container-horizontal" : "card-container"
    let cardClassName = isHorizontal ? "poker-card-horizontal" : "poker-card"
    let cardBackgroundWidth = (isHorizontal ? (useCardImage?.width ?? 0) * 1.75 : useCardImage?.width) ?? 0
    let cardBackgroundHeight = (isHorizontal ? (useCardImage?.height ?? 0) * 1.71 : useCardImage?.height) ?? 0

    function handleCardClicked() {
        if (onClick != null) {
            onClick(selectKey)
        }
    }

    // TODO - Try to make this dynamic
    function getCardValueFontSize(value: string): number {
        let length = value.length
        if (length < 5) {
            return 32
        } else if (length < 35) {
            return 24
        } else if (length < 60) {
            return 18
        } else if (length < 100) {
            return 16
        } else {
            return 12
        }
    }

    useEffect(() => {
        if (emote === "") {
            setPrevEmote(emote)
            return
        }
        if (emote !== prevEmote) {
            anime({
                targets: ".playerEmote" + selectKey,
                opacity: [1, 0],
                translateY: [0, -200],
                scale: [2, 4],
                easing: 'easeInOutSine',
                duration: 2500,
                complete: function () {
                    onEmoteFinished()
                }
            })
        }
    }, [emote])

    useEffect(() => {
        anime({
            targets: ".zoomable" + selectKey,
            scale: isZoomed ? 2.5 : 1,
            easing: 'easeInOutSine',
            duration: 300
        })
    }, [isZoomed])

    return (
        <div className={cardContainerClassName + " " + (isSelected ? "poker-card-selected" : "poker-card-unselected")} onClick={handleCardClicked}>
            <div className={cardClassName + " " + ((inGame ? "poker-card-game" : "")) + " zoomable" + selectKey}
                style={{ marginBottom: "8px", alignContent: "center", justifyContent: "center", zIndex: 10, WebkitTransform: invertFlip ? 'rotateY(180deg)' : '' }}>
                <div className={"card-front" + unselectedCssClass} style={{
                    backgroundColor: useCustomCardColor ? customCardColor : theme.palette.primary.main,
                    backgroundImage: useCardImage != null ? `url("${useCardImage.url}")` : '',
                    backgroundSize: useCardImage != null ? `${cardBackgroundWidth}px ${cardBackgroundHeight}px` : '50px',
                    WebkitBackgroundSize: useCardImage != null ? `${cardBackgroundWidth}px ${cardBackgroundHeight}px` : '50px',
                    transform: isHorizontal ? "rotateZ(90deg)" : ""
                }} />
                <div className={"card-back" + unselectedCssClass}
                    style={{
                        transform: "rotateY(180deg)" + (isHorizontal ? " rotateZ(90deg)" : ""),
                        fontSize: getCardValueFontSize(value ?? ""),
                        color: useCustomCardColor ? customCardColor : theme.palette.primary.dark,
                    }}>
                    <div style={{
                        transform: isHorizontal ? "rotateZ(90deg)" : "",
                        backfaceVisibility: "hidden"
                    }}>
                        {value != null ? value : ""}
                    </div>
                </div>
            </div>
            {!isAnonymous ? <Typography align="center" style={{ fontSize: 18, overflowWrap: "break-word", width: "120px", transform: isHorizontal ? "translateY(-30px)," : "" }}> {name} </Typography> : <></>}
            <div className={"playerEmote" + selectKey} style={{ position: "absolute", zIndex: 15 }}>{emote}</div>

        </div>
    )
}